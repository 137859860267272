<template>
  <div class="base-popover">
    <!-- toggler -->
    <button
      class="btn btn-sm btn-link"
      @mouseover="contentVisible = true"
      @mouseleave="contentVisible = false"
    >
      <span class="icon-help"></span>
    </button>
    <!-- content -->
    <div
      class="
        popover-content
        border-1 border-muted
        shadow
        bg-light
        p-2
        text-left
      "
      v-show="contentVisible"
    >
    <!-- <div class="arrow"></div> -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    contentVisible: false,
  }),
};
</script>

<style lang="scss" scoped>

</style>